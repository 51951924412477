<template>
  <div class="profile">
    <router-link to="/profile" class="main-button">Go to profile</router-link>
    <div class="phrases" v-if="phrase.phrase">
      <div class="d-flex flex-column justify-content-between">
        <div class="phrase-item box-right w-100 mt-5">
          <p>
            <i>"{{ phrase.phrase.phrase }}"</i> &nbsp;
            {{ phrase.phrase.author.name }}
          </p>
          <div class="phrase-footer">
            <div class="phrase-rating">
              <span v-if="phrase.rating">{{
                getStars(phrase.rating).join("")
              }}</span>
            </div>
            <div class="phrase-date">{{ formatDate(this.phrase.date) }}</div>
          </div>
        </div>
      </div>

      <Rate :rating="phrase.rating" @change="handleRatingChange" />
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import Rate from "../components/rate/Rate.vue";

export default {
  data() {
    return {
      phrase: {},
      id: 0,
    };
  },
  components: {
    Rate,
  },
  async created() {
    this.id = this.$route.params["id"];
    const res = await this.$http.phrase.getCustomerPhrase(this.id);

    this.phrase = res.data;
  },
  methods: {
    formatDate(d) {
      return moment(d).format("L");
    },
    async handleRatingChange(val) {
      await this.$http.phrase.ratePhrase(this.id, val);
      this.phrase.rating = val;
    },
    getStars(r) {
      const arr = [];

      for (let i = 1; i <= 5; i++) {
        arr.push(r < i ? "☆" : "★");
      }

      return arr;
    },
  },
};
</script>
