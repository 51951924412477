<template>
  <div class="container-fluid reviews">
    <span
      v-for="(v, i) in ['★', '★', '★', '★', '★']"
      v-bind:key="i"
      @click="handleClick(i + 1)"
    >
      <span :style="i + 1 <= rate ? 'color: #ff4d00' : ''" class="rate-star"
        >★</span
      >
    </span>
  </div>
</template>

<style>
.rate-star {
  font-size: 37px;
  margin: 0 5px;
}
</style>
<script>
export default {
  name: "Rate",
  props: ["rating"],
  created() {
    this.rate = this.rating;
  },
  data() {
    return {
      rate: 0,
    };
  },
  methods: {
    handleClick(newRate) {
      this.rate = newRate;
      this.$emit("change", newRate);
    },
  },
};
</script>
